<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      v-permission="['90004']"
      class="mb-5"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-card>
          <v-card-text
            class="d-flex align-center justify-space-between pa-4"
          >
            <div>
              <h2 class="font-weight-semibold mb-1">{{ orderTotal.total }}</h2>
              <span>订单总数</span>
            </div>
            <v-avatar
              rounded
              color="primary"
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiOrderNumericDescending }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-card>
          <v-card-text
            class="d-flex align-center justify-space-between pa-4"
          >
            <div>
              <h2 class="font-weight-semibold mb-1">{{ orderTotal.review_total }}</h2>
              <span>待审核订单</span>
            </div>
            <v-avatar
              rounded
              color="secondary"
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiPrinterEye }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-card>
          <v-card-text
            class="d-flex align-center justify-space-between pa-4"
          >
            <div>
              <h2 class="font-weight-semibold mb-1">{{ orderTotal.tobeshipped_total }}</h2>
              <span>待发货订单</span>
            </div>
            <v-avatar
              rounded
              color="error"
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiTruck }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-card>
          <v-card-text
            class="d-flex align-center justify-space-between pa-4"
          >
            <div>
              <h2 class="font-weight-semibold mb-1">{{ orderTotal.finished_total }}</h2>
              <span>已完成订单</span>
            </div>
            <v-avatar
              rounded
              color="success"
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiStoreCheck }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="py-16 text-center">
      <v-card-text>
        <v-icon
          disabled
          size="80"
        >
          {{ icons.mdiRocketLaunch }}
        </v-icon>
        <h1 class="font-weight-light mt-3 mb-3 text-h3">
          {{ title }}
        </h1>

        <span
          class="font-weight-light text-subtitle-1"
        >
          &copy;2022 湖南楚润餐饮 hunanchuruncanyin.com 湘ICP备2022015845号
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  mdiOrderNumericDescending,
  mdiPrinterEye,
  mdiTruck,
  mdiStoreCheck,
  mdiRocketLaunch,
} from '@mdi/js'

import {
  getOrderTotal,
} from '@/api/order'
import store from '@/store'

export default {
  data() {
    return {
      icons: {
        mdiOrderNumericDescending,
        mdiPrinterEye,
        mdiTruck,
        mdiStoreCheck,
        mdiRocketLaunch,
      },
      title: store.getters.distributor,
      orderTotal: {
        total: null,
        review_total: null,
        tobeshipped_total: null,
        finished_total: null,
      },
    }
  },
  created() {
    this.getTotal()
  },
  methods: {
    async getTotal() {
      const { data } = await getOrderTotal()
      this.orderTotal = data
    },
  },
}
</script>

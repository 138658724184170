import request from '@/utils/request'

export function getOrders(params) {
  return request.get('/api/v1/backend/orders', { params })
}

export function getOrder(id) {
  return request.get(`/api/v1/backend/orders/${id}`)
}

export function operateOrder(id, data) {
  return request.put(`/api/v1/backend/orders/${id}`, data)
}

export function deleteOrder(id) {
  return request.delete(`/api/v1/backend/orders/${id}`)
}

export function getOrderTotal() {
  return request.get('/api/v1/backend/orders/total')
}
